import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import Layout from '../components/layout';
import {
  withAuthorization,
} from '../components/Session';
import HeadData from "../data/HeadData"
import BackButton from '../components/Common/BackButton';
import SampleDocumentsSectionNew from '../components/Sections/SampleDocumentsSectionNew';

const SampleDocumentsPageBase = () => (
  <DashboardWrapper pageName='Sample Documents'>
    <div className="relative pt-16 pb-32 md:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <SampleDocumentsSectionNew />

        <BackButton buttonLink='/dashboard' />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const SampleDocumentsPage = compose(
  withAuthorization(condition),
)(SampleDocumentsPageBase);

const SampleDocuments = () => (
  <Layout>
    <HeadData title='Sample Documents' />
    <SampleDocumentsPage />
  </Layout>
)

export default SampleDocuments
