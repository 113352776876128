import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import DocumentCard from '../Common/DocumentCard';
import DocumentCardsContainer from '../Common/DocumentCardsContainer';

const SampleDocumentsSectionNew = () => {
  const data = useStaticQuery(graphql`
    query SampleDocumentsSectionNewQuery {
      prismicSampleDocuments {
        data {
          section_title
          section_description {
            richText
          }
          pdf_files_list {
            pdf_file_title
            pdf_download_link {
              url
            }
            coming_soon
          }
        }
      }
    }
  `);

  const doc = data.prismicSampleDocuments

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>{doc.data.section_title}</h2>
        {doc.data.section_description.richText
          ?
          <div className="mt-6">
            <RichText
              render={doc.data.section_description.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }
      </div>

      <DocumentCardsContainer containerTitle="Downloadable Documents">
        {doc.data.pdf_files_list.map(item => {
          return (
            item.coming_soon
            ?
            <DocumentCard
              cardTitle={item.pdf_file_title}
              internalLink
              buttonComingSoon
            />
            :
            <DocumentCard
              cardTitle={item.pdf_file_title}
              buttonLink={item.pdf_download_link.url}
            />
          )
        })}
      </DocumentCardsContainer>
    </>
  )
};

export default SampleDocumentsSectionNew;
